import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'
import CustomerEnablementPage from '../ui/pages/CustomerEnablementPage'

const CustomerEnablement = props => (
  <>
    <HelmetComponent
      title={metaTags.customerEnablementTitle}
      description={metaTags.customerEnablement}
      page="customer-enablement"
    />
    <AppWrapper {...props}>
      <CustomerEnablementPage />
    </AppWrapper>
  </>
)

export default CustomerEnablement
